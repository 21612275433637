body {
  font-family: 'Montserrat', sans-serif;
}

.home {
  position: relative;
}

.home .header {
  display: flex;
  justify-content: space-between;
  padding: 8px 24px 16px 24px;
  border-bottom: 1px solid #EAECED;
}

.home .header .icon {
  width: 32px;
}

.home .header .logo {
  width: 72px;
}

.home .top-text {
  font-size: 24px;
  font-weight: 600;
  line-height: 27px;
  margin-top: 16px;
}

.home .question_area {
  padding: 0px 24px;
}

.home .question_area .icon {
  width: 32px;
}

.home .question_area form {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.home .question_area form label {
  width: 100%;
}

.home .question_area form textarea,
.home .question_area form input {
  font-family: 'Montserrat', sans-serif;
  width: 100%;
  font-size: 16px;
  border: none;
}

.home .question_area form input:focus {
  border: none;
  outline: none;
}

.home .question_area .category_area .categories ul {
  padding-left: 0;
}

.home .question_area .category_area .categories .category {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 10px;
  border-radius: 20px;
  background: #F6F6F6;
  list-style: none;
  margin-top: 8px;
}

.home .question_area .category_area .categories .category .text {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.home .question_area .category_area .categories .category .category-icon {
  width: 32px;
}

.home .question_area .category_area .categories .category .left {
  display: flex;
  align-items: center;
}

/* @keyframes slideInFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.questions {
  animation: slideInFromRight 0.5s ease-out forwards;
}

.response {
  animation: slideInFromRight 0.5s ease-out forwards;
}

.home {
  animation: slideInFromRight 0.5s ease-out forwards;
} */

.questions .header {
  display: flex;
  justify-content: space-between;
  padding: 8px 24px 16px 24px;
  border-bottom: 1px solid #EAECED;
}

.questions .header .icon {
  width: 32px;
}

.questions .header .logo {
  width: 72px;
  margin-left: 12px;
}

.questions .icon {
  width: 32px;
}

.questions form {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.questions form label {
  width: 100%;
}

.questions form input {
  font-family: 'Montserrat', sans-serif;
  width: 100%;
  font-size: 16px;
  border: none;
}

.questions form input:focus {
  border: none;
  outline: none;
}

.questions .question_area {
  padding: 0px 24px;
}

.questions .title {
  display: flex;
  align-items: center;
  font-size: 17px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 20px;
}

.questions .title img {
  width: 32px;
}

.questions .sub_category {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin-top: 24px;
  margin-bottom: 16px;
}

.questions .data ul {
  margin: 4px 0px;
  margin-top: 40px;
  border-radius: 20px;
  background: #F6F6F6;
  padding: 12px 8px;
}

.questions .data ul {
  padding-left: 0;
  margin: 0;
}

.questions .data .question {
  list-style: none;
  display: flex;
  justify-content: space-between;
  margin: 8px;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.questions .data .show_button {
  color: #38ABC5;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  border: none;
  background: #F6F6F6;
}

.questions .data .show_button:focus {
  border: none;
  outline: none;
}

.questions .data .arrow_icon {
  width: 32px;
}

.questions .data .show_text {
  display: flex;
  align-items: center;
}

.response .header {
  display: flex;
  justify-content: space-between;
  padding: 8px 24px 16px 24px;
  border-bottom: 1px solid #EAECED;
}

.response .header .icon {
  width: 32px;
}

.response .header .logo {
  width: 72px;
  margin-left: 12px;
}

.response .response_area {
  padding: 0px 24px;
}

.response .response_area .breadcrumb {
  display: flex;
}

.response .response_area .breadcrumb .breadcrumb-link {
  display: flex;
  align-items: center;
  color: #38ABC5;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  padding: 4px 9px 4px 0px;
}

.response .response_area .icon {
  width: 32px;
}

.response .response_area form {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.response .response_area form label {
  width: 100%;
}

.response .response_area form textarea,
.response .response_area form input {
  font-family: 'Montserrat', sans-serif;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  border: none;
  background: transparent;
}

.response .response_area form textarea:focus,
.response .response_area form input:focus {
  border: none;
  outline: none;
}

.response .response_area .info h3 {
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
}

.response .response_area .info .text {
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
}

.response .response_area .info .image img {
  width: -webkit-fill-available;
}

.response .helpful .title {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin-top: 30px;
  margin-bottom: 20px;
}

.response .helpful .icons {
  display: flex;
  gap: 12px;
}

.response .helpful .icons .button {
  background: white;
  border-radius: 28px;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 0;
  color: black;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.response .helpful .icons .up_icon {
  border: 1px solid #109E6F;
}

.response .helpful .icons .down_icon {
  border: 1px solid #EF6262;
}

.response .helpful .icons .active-up {
  background: #109E6F;
}

.response .helpful .icons .active-down {
  background: #EF6262;
}

.response .helpful .share {
  border-radius: 20px;
  border: 1px solid #EAECED;
  background: #FFF;
  padding: 16px 20px 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.response .helpful .share .left {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.response .helpful .share .right {
  display: flex;
  gap: 4px;
}

.response .feedback .title {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin-top: 50px;
}

.response .feedback form {
  margin-top: 20px;
  border-radius: 36px;
  border: 1px solid #EAECED;
  height: 80px;
}

.response .feedback form label textarea {
  padding: 12px 16px 12px 20px;
  width: calc(100% - 38px);
}

.footer {
  margin-top: 70px;
  margin-bottom: 10px;
  /* position: fixed;
  bottom: 10px;
  width: calc(100% - 48px); */
}

.footer .top-text {
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  margin-top: 16px;
}

.footer .community {
  border-radius: 20px;
  background: #F6F6F6;
  margin-top: 20px;
  padding: 12px 10px;
}

.footer .community .title {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer .community .title .left {
  display: flex;
  align-items: center;
  column-gap: 4px;
}

.footer .community p {
  color: #7F8598;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.1px;
  width: 220px;
  margin-left: 36px;
}

.footer .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(11, 18, 22, 0.80);
  backdrop-filter: blur(6.795704364776611px);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer .modal {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  width: calc(100% - 40px);
  padding: 20px;
  border-top-left-radius: 36px;
  border-top-right-radius: 36px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1001;
  text-align: center;
}

.footer .modal .line {
  width: 60px;
  height: 5px;
  flex-shrink: 0;
  border-radius: 100px;
  background: #EAECED;
  margin: auto;
  transition: margin-top 0.5s ease;
}

.footer .modal h3 {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin-top: 50px;
}

.footer .modal p {
  color: #7F8598;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.footer .modal button {
  padding: 21px 32px;
  border-radius: 100px;
  background: #112D35;
  color: white;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  display: block;
  margin: auto;
  width: 100%;
  margin-bottom: 10px;
  outline: auto;
}

.footer .modal-content {
  text-align: center;
}

.footer .modal h2 {
  margin-bottom: 10px;
}

.footer .modal p {
  margin-bottom: 0;
}

.search .search_box {
  border: 1px solid #EAECED;
  border-radius: 36px;
  padding: 8px 16px;
  margin-top: 16px;
  margin-bottom: 8px;
}

.search .search_box button {
  background: transparent;
  border: none;
}

.search .overlay {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 20px;
  box-sizing: border-box;
  z-index: 999;
}

.search .overlay .info {
  border-radius: 20px;
  border: 1px solid #EAECED;
  padding: 10px;
}

.search .overlay .info ul {
  padding-left: 0;
}

.search .overlay p {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  padding: 16px 12px 12px 12px;
  border-radius: 20px 20px 0px 0px;
  border-bottom: 1px solid #BDC1CD;
}

.search .overlay .search-result {
  list-style: none;
  color: #7F8598;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  padding: 19px 10px 19px 20px;
}

.search .overlay .search-result:hover {
  border-radius: 20px;
  background: #F6F6F6;
}

.search_result .info {
  padding: 0px 24px;
}

.search_result .info .title {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  padding: 9px 0px;
}

.search_result .info .count {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  padding: 9px 0px;
  margin-bottom: 28px;
}

.search_result .info .count .title {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.search_result .info .count .sbutitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.search_result .info .responses .search {
  border-bottom: 1px solid #EAECED;
}

.search_result .info .responses .search .category {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin-top: 10px;
}

.search_result .info .responses .search .question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 10px;
  border-radius: 20px;
  background: #F6F6F6;
  list-style: none;
  margin-top: 8px;
}

.search_result .info .responses .search .question .text {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.search_result .info .responses .search .question .right img {
  width: 32px;
}

.search_result .info .responses .search .question .left {
  display: flex;
  align-items: center;
}

.search_result .info .responses .search .response {
  color: #7F8598;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.search_result .footer {
  padding: 24px;
}

.search_result form {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search_result .icon {
  width: 32px;
}

.search_result form textarea,
.search_result form input {
  font-family: 'Montserrat', sans-serif;
  width: 100%;
  font-size: 16px;
  border: none;
}

.search_result form input:focus {
  border: none;
  outline: none;
}

.thank-you-message {
  border-color: #46b450;
  background: #e0ffc7;
  border: 1px solid #b4d39b;
  padding: 10px;
  margin-top: 10px;
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.pagination .left {
  display: flex;
}

.pagination .right {
  display: flex;
}

.pagination .right button {
  font-size: 17px;
  font-weight: 600;
  line-height: 22px;
}

.pagination .right img {
  width: 32px;
  height: 32px;
  display: flex;
}

.pagination .right .disabled {
  filter: opacity(0.5);
  cursor: not-allowed;
}

.pagination .cover {
  width: 32px;
  height: 32px;
  display: flex;
}

.pagination .active {
  background: #7EC4D3;
  border-radius: 50%;
}

.pagination button {
  background-color: transparent;
  border: none;
  color: #000;
  margin: 0 5px;
  cursor: pointer;
  font-size: 17px;
  font-weight: 500;
  line-height: 28px;
}

.pagination button.active {
  background-color: #7EC4D3;
  color: #fff;
  border-radius: 50%;
}

.pagination button:disabled {
  color: #ccc;
  cursor: not-allowed;
}
